// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2024 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================
// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from "styled-components";

import { palette } from "./Palette";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    background-color: ${palette.solid.white};
    color: ${palette.solid.darkgrey};
    overscroll-behavior-y: none;
  }

  input, textarea {
    font-family: "Inter", sans-serif;
  }
`;

export * from "./constants";
export * from "./Palette";
export * from "./Typography";
